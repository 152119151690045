$floori-fonts-array: (
    'inter': 'Inter',
    'roboto': 'Roboto',
    'avenir-next': 'Avenir Next',
    'madefor-display': 'Wix Madefor Display',
);

body {
    @each $floori-font-name, $floori-font in $floori-fonts-array {
        &.floori-#{$floori-font-name} {
            font-family: #{$floori-font}, 'sans-serif';

            button {
                font-family: #{$floori-font}, 'sans-serif';
            }

            textarea {
                &::placeholder {
                    font-family: #{$floori-font}, 'sans-serif';
                }
            }
        }
    }
}
